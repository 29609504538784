import PropTypes from "prop-types";
import React from "react";
import UploadImageComponent from "../../components/upload-image";
import { useUpdateStaffImage } from "../../hooks/staff.hooks";

const StaffSignupUploadImage = ({
  params,
  handleUpdateParams,
  type,
  title,
  errors,
  isLightLogo = false,
  aspect,
}) => {
  const uploadFile = useUpdateStaffImage();
  const handleUploadFile = (file) => {
    const formData = new FormData();
    formData.append(`file`, file);
    uploadFile.mutate(formData, {
      onSuccess: (imageURL) => {
        handleUpdateParams(type, imageURL);
      },
    });
  };

  return (
    <div className="add-staff__field">
      <div className="add-staff__field-title">
        {title}
        {Boolean(errors[type]) && (
          <span className="add-staff__field-title--error">
            (This field is required)
          </span>
        )}
      </div>
      <UploadImageComponent
        imageURL={params[type]}
        handleSetCroppedImage={handleUploadFile}
        aspect={aspect}
      />
    </div>
  );
};

StaffSignupUploadImage.propTypes = {
  type: PropTypes.string,
};

export default StaffSignupUploadImage;
