import { ClickAwayListener, TextField } from "@mui/material";
import { useState } from "react";
import { ChromePicker } from "react-color";
import { isValidHexColor } from "../../utils/color-valid-check";

const StaffSignupColorPicker = ({
  handleUpdateParams,
  type,
  title,
  params,
}) => {
  const [selectedColor, setSelectedColor] = useState(params?.[type] || "");
  const [pickerVisible, setPickerVisible] = useState(false);

  const handleOpenPicker = () => {
    setPickerVisible(!pickerVisible);
  };
  const handleClickAway = () => {
    setPickerVisible(false);
  };
  const handleColorChange = ({ hex }) => {
    setSelectedColor(hex);
    handleUpdateParams(type, hex);
  };
  const handleInputColor = (e) => {
    setSelectedColor(e.target.value);
    handleUpdateParams(
      type,
      isValidHexColor(e.target.value) ? e.target.value : ""
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="add-staff__field">
        <div className="add-staff__field-title">{title}</div>
        <div className="add-staff-color-picker">
          <TextField
            label=""
            id={type}
            name={type}
            value={selectedColor}
            size="small"
            error={!isValidHexColor(selectedColor)}
            className={`add-staff__field-input ${type}`}
            onChange={handleInputColor}
            helperText={!isValidHexColor(selectedColor) && "Invalid color. "}
          />
          <div style={{ position: "relative" }}>
            <div
              className="add-staff-color-picker__swatch"
              onClick={handleOpenPicker}
            >
              <div
                className="add-staff-color-picker__color"
                style={{ background: `${selectedColor}` }}
              />
            </div>
            {pickerVisible && (
              <div style={{ position: "absolute", zIndex: 1, right: 0 }}>
                <ChromePicker
                  color={selectedColor}
                  onChange={handleColorChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};

StaffSignupColorPicker.propTypes = {};

export default StaffSignupColorPicker;
