import classNames from "classnames";
import { staffRoleEnum } from "./staff-sign-up.enum";
interface staffRoleButton {
  title: string;
  selected?: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

interface staffRoleGroup {
  staffRole: staffRoleEnum;
  handleSetStaffRole: any;
}

const StaffRoleButton = ({ title, selected, onClick }: staffRoleButton) => {
  return (
    <div
      onClick={onClick}
      className={classNames("staff-role__button", { selected })}
    >
      {title}
    </div>
  );
};

const StaffRoleGroup = ({ staffRole, handleSetStaffRole }: staffRoleGroup) => {
  return (
    <div className="staff-role__button-group">
      <StaffRoleButton
        title="Mortgage Broker"
        selected={staffRole === staffRoleEnum.BROKER}
        onClick={() => handleSetStaffRole(staffRoleEnum.BROKER)}
      />
      <StaffRoleButton title="Coming Soon" onClick={() => {}} />
    </div>
  );
};

export default StaffRoleGroup;
