import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import { Button, CircularProgress, Dialog } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import StaffRoleGroup from "../../features/signup/staff-sign-up-role-group.tsx";
import { staffRoleEnum } from "../../features/signup/staff-sign-up.enum.ts";
import StaffSignupForm from "../../features/signup/staff-signup-form";
import { useCreateCompanyAdmin } from "../../hooks/staff.hooks";
import { splitNameFromFullName } from "../../utils/user-data";

const loadingSpinner = <CircularProgress color="inherit" size={24} />;

const validationSchema = Yup.object({
  email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  companyName: Yup.string("Enter company Name").required(
    "Company name is required"
  ),
  companyDomain: Yup.string("Enter company domain").required(
    "Company domain is required"
  ),

  brokerName: Yup.string("Enter broker name").required("Name is required"),
  phoneNumber: Yup.string("Enter broker Phone Number").required(
    "Phone Number is required"
  ),
  connectiveAccountId: Yup.string("Enter broker Connective Id"),
  introductionVideoUrl: Yup.string("Enter Introduction Video URL"),
  msTeamHook: Yup.string("Enter Microsoft Team Hook id"),
  slackHook: Yup.string("Enter Slack Hook id"),
  activeCampaignContactListId: Yup.string("Enter Active Campaign List id"),
  calendlyUrl: Yup.string("Enter broker Calendly URL"),
  imageUrl: Yup.string("Enter professional photo").required(
    "Professional photo is required"
  ),
  companyLogoURL: Yup.string("Input company Logo").required(
    "company Logo is required"
  ),
  companyLogoDarkURL: Yup.string("Input company Logo").required(
    "company Logo is required"
  ),
});

const Signup = () => {
  const [staffRole, setStaffRole] = useState(staffRoleEnum.BROKER);
  const createCompanyAdmin = useCreateCompanyAdmin();
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const ServicesRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();
  const handleSetStaffRole = (role) => {
    setStaffRole(role);
  };

  const gotoServices = () => {
    ServicesRef.current.scrollIntoView({
      top: "20px",
      behavior: "smooth",
      block: "start",
    });
  };

  const handleClose = () => {
    setOpen(false);
    setIsSuccess(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleCreateBroker = async (brokerDetailParams) => {
    setIsError(false);
    createCompanyAdmin.mutate(brokerDetailParams, {
      onSuccess: () => {
        handleOpen();
        setIsSuccess(true);
      },
      onError: (errors) => {
        setIsError(true);

        const {
          data: { message },
        } = errors;

        enqueueSnackbar(message, { variant: "error" });
      },
    });
  };
  useEffect(() => {
    if (isError) {
      gotoServices();
    }
  }, [isError]);

  const formik = useFormik({
    initialValues: {
      email: "",
      businessPhoneNumber: "",
      customerSupportEmail: "",
      companyNumber: "",
      brokerName: "",
      financialServicesLicenseNumber: "",
      creditLicenseNumber: "",
      phoneNumber: "",
      connectiveAccountId: "",
      introductionVideoUrl: "",
      staffRole: [staffRoleEnum.COMPANY_ADMIN],
      calendlyUrl: "",
      imageUrl: "",
      companyName: "",
      companyDomain: "",
      primaryColor: "#33FF99",
      secondaryColor: "#131C24",
      companyLogoURL: "",
      companyIconURL: "",
    },
    validate: () => {
      if (
        formik.errors?.email ||
        formik.errors?.brokerName ||
        formik.errors?.phoneNumber
      ) {
        gotoServices();
      }
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const [brokerFirstName, brokerLastName] = splitNameFromFullName(
        values.brokerName
      );
      const brokerDetailParams = {
        userDetails: {
          email: values.email,
          firstName: brokerFirstName,
          lastName: brokerLastName,
          state: "WAITING_VERIFICATION",
          phoneNumber: values.phoneNumber,
        },
        staffDetails: {
          introductionVideoUrl: values.introductionVideoUrl,
          staffRole: values.staffRole,
          imageUrl: values.imageUrl,
          calendlyUrl: values.calendlyUrl,
          brokerDetails: {
            connectiveAccountId: values.connectiveAccountId,
          },
        },
        companyDetails: {
          businessPhoneNumber: values.businessPhoneNumber,
          customerSupportEmail: values.customerSupportEmail,
          financialServicesLicenseNumber: values.financialServicesLicenseNumber,
          creditLicenseNumber: values.creditLicenseNumber,
          connectiveAccountId: values.connectiveAccountId,
          connectivePartnerId: values.connectivePartnerId,
          connectiveApiKey: values.connectiveApiKey,
          connectiveApiToken: values.connectiveApiToken,
          companyNumber: values.companyNumber || "",
          name: values.companyName,
          domain: values.companyDomain,
          themeDetails: {
            primaryColor: values.primaryColor,
            secondaryColor: values.secondaryColor,
            logoUrl: values.companyLogoURL,
            logoDarkUrl: values.companyLogoDarkURL,
            iconUrl: values.companyIconURL,
            iconDarkUrl: values.companyIconDarkURL,
          },
        },
      };
      await handleCreateBroker(brokerDetailParams);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    formik.setFieldValue("staffRole", [staffRoleEnum.COMPANY_ADMIN, staffRole]);
    formik.setFieldValue("creditLicenseNumber", "");
    formik.setFieldValue("financialServicesLicenseNumber", "");
    formik.setFieldValue("connectiveAccountId", "");
    formik.setFieldValue("connectivePartnerId", "");
    formik.setFieldValue("connectiveApiKey", "");
    formik.setFieldValue("connectiveApiToken", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffRole]);

  return (
    <div className="staff-signup">
      <div className="staff-signup__heading">
        Get set up in minutes, maintain your customers for life!
      </div>
      <div className="staff-signup__content">
        <div className="staff-signup-content__heading">
          What type of advisor are you?
        </div>
        <StaffRoleGroup
          staffRole={staffRole}
          handleSetStaffRole={handleSetStaffRole}
        />
        <StaffSignupForm
          refs={ServicesRef}
          formik={formik}
          staffRole={staffRole}
        />
        <div className="staff-signup-content__heading">
          If all looks good. Launch The App
        </div>
        <div>
          <Button
            className="staff-signup__launch-button"
            onClick={formik.handleSubmit}
          >
            {createCompanyAdmin.isLoading ? (
              loadingSpinner
            ) : (
              <>
                Launch App
                <RocketLaunchOutlinedIcon style={{ marginLeft: 10 }} />
              </>
            )}
          </Button>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="thank-you-dialog__container">
          <div className="thank-you-dialog__title">
            Thank you<span>.</span>
          </div>
          <div className="thank-you-dialog__content">
            Thanks for signing up. We will make contact with you in the next
            24hrs to formally approve you.
          </div>
        </div>
      </Dialog>
    </div>
  );
};

Signup.propTypes = {};

export default Signup;
